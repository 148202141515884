'use client';

import { Tab } from '@headlessui/react';
import { motion, useReducedMotion } from 'framer-motion';
import { Fragment, useLayoutEffect, useState } from 'react';

const SingleTab = ({ label }: { label: string }) => {
	const reduceMotion = useReducedMotion();
	const [hasRendered, setHasRendered] = useState<boolean>(false);

	useLayoutEffect(() => {
		setHasRendered(true);
	}, []);

	return (
		<Tab as={Fragment}>
			{({ selected }) => (
				<button
					className={`shadow-border-bottom-sm relative w-full px-4 py-2 ${
						selected
							? 'bg-primary-700 text-white shadow-md'
							: 'bg-primary-100 text-primary-800'
					} rounded-3xl text-lg font-bold transition-all after:rounded-3xl active:opacity-70 hover:opacity-80`}
				>
					{label}
					{hasRendered && (
						<motion.div
							initial={{
								scaleX: selected ? 1 : 0,
							}}
							animate={{
								scaleX: selected ? 1 : 0,
								transition: {
									duration: reduceMotion ? 0.01 : 0.3,
								},
							}}
							className="absolute inset-x-0 bottom-1.5 mx-8 h-0.5 rounded-full bg-white transition-colors duration-300 group-hover:bg-white/50 md:mx-16"
						/>
					)}
				</button>
			)}
		</Tab>
	);
};

export default SingleTab;
