'use client';

import useLocalStorage from '@/hooks/useLocalStorage';
import { mdiEyedropper } from '@mdi/js';
import { motion, useReducedMotion } from 'framer-motion';
import {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Button,
	ColorArea,
	ColorPicker,
	ColorSlider,
	ColorSwatch,
	ColorSwatchPicker,
	ColorSwatchPickerItem,
	ColorThumb,
	Dialog,
	DialogTrigger,
	Input,
	Label,
	Popover,
	SliderOutput,
	SliderTrack,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import IconButton from '../Button/IconButton';

const TriggerButton = motion(Button);

const Thumb = () => (
	<ColorThumb
		className={twMerge(
			'size-6 cursor-grab rounded-md border-white shadow-md ring-2 ring-black ring-offset-2 active:cursor-grabbing',
			'focus-visible:outline-double focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-black',
		)}
	/>
);

export const alphaPatternStyle = {
	backgroundColor: 'white',
	backgroundImage:
		'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.25)), repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, #ffffff 25%, #ffffff 75%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.25))',
	backgroundPosition: '0 0, 0.5rem 0.5rem',
	backgroundSize: '1rem 1rem',
};

const isColorValid = (color: string) => color.match(/^#([0-9a-f]{3}){1,2}$/i);

const SingleColorPicker: FC<{
	label: string;
	color: string;
	isOpacityAllowed?: boolean;
	setColor: Dispatch<SetStateAction<string>>;
}> = ({ label, color, isOpacityAllowed = false, setColor }) => {
	const reduceMotion = useReducedMotion();

	const [inputValue, setInputValue] = useState(color);

	const [lastSwatches, setLastSwatches] = useLocalStorage<string[]>(
		'SingleColorPickerLastSwatches',
		[],
	);

	const isRGBAllTheSame = useMemo(() => {
		// Check if red, green and blue are the same in the HEX color
		const [red, green, blue] =
			color.replace('#', '').match(/.{1,2}/g) ||
			[].map((hex) => parseInt(hex, 16));

		return red === green && red === blue;
	}, [color]);

	// Remove opacity from color if it's not allowed
	useEffect(() => {
		if (!isOpacityAllowed) {
			if (color.length === 5) setColor(color.slice(0, 4));
			if (color.length === 9) setColor(color.slice(0, 7));
		}
	}, [isOpacityAllowed, color]);

	// Set input value to color if it changes
	useEffect(() => {
		setInputValue(color);
	}, [color]);

	return (
		<div className="flex flex-col items-center justify-center gap-1">
			<ColorPicker
				value={color}
				onChange={(value) => setColor(value.toString())}
			>
				<DialogTrigger
					onOpenChange={() => {
						// Remove the color if it already exists
						// Add the color to the front of the last swatches
						setLastSwatches((swatches) => [
							color,
							...swatches
								.filter((swatch) => swatch !== color)
								.splice(0, 11),
						]);
					}}
				>
					<TriggerButton
						className="flex flex-col items-center gap-1 rounded-lg"
						whileHover={{
							scale: reduceMotion ? 1 : 1.05,
						}}
						whileTap={{
							scale: reduceMotion ? 1 : 0.95,
						}}
						aria-label={`${label} kleurenkiezer`}
					>
						<ColorSwatch className="border-1 size-12 rounded-full border-white shadow-lg ring-2 ring-slate-700 ring-offset-2" />
						<span className="font-bold">{label}</span>
					</TriggerButton>
					<Popover>
						<Dialog className="shadow-border-bottom-md grid w-full min-w-56 overflow-hidden overflow-y-auto rounded-lg bg-white/80 shadow-xl backdrop-blur-md after:rounded-lg dark:bg-black/80">
							<div className="overflow-hidden rounded-lg border-2 border-black/10">
								<div className="grid gap-1 p-2 pb-2.5">
									<ColorArea
										colorSpace="hsb"
										xChannel="hue"
										yChannel="brightness"
										className={twMerge(
											'shadow-border-bottom-sm aspect-video w-full rounded-md transition-all duration-500 after:rounded-md',
											isRGBAllTheSame ? 'opacity-10' : '',
										)}
										isDisabled={isRGBAllTheSame}
										aria-label="Kleurenveld"
									>
										{!isRGBAllTheSame && <Thumb />}
									</ColorArea>
									<ColorSlider
										colorSpace="hsl"
										channel="lightness"
									>
										<div className="flex px-1 font-bold">
											<Label className="grow">
												Helderheid
											</Label>
											<SliderOutput />
										</div>
										<SliderTrack className="shadow-border-bottom-sm h-8 w-full rounded-md after:rounded-md ">
											<div className="translate-y-4">
												<Thumb />
											</div>
										</SliderTrack>
									</ColorSlider>
									{(
										[
											{
												id: 'red',
												label: 'Rood',
											},
											{
												id: 'green',
												label: 'Groen',
											},
											{
												id: 'blue',
												label: 'Blauw',
											},
										] as const
									).map((colorSlider) => (
										<ColorSlider
											key={colorSlider.id}
											channel={colorSlider.id}
										>
											<div className="flex px-1 font-bold">
												<Label className="grow">
													{colorSlider.label}
												</Label>
												<SliderOutput />
											</div>
											<SliderTrack className="shadow-border-bottom-sm h-8 w-full rounded-md after:rounded-md ">
												<div className="translate-y-4">
													<Thumb />
												</div>
											</SliderTrack>
										</ColorSlider>
									))}

									{isOpacityAllowed && (
										<ColorSlider channel="alpha">
											<div className="flex px-1 font-bold">
												<Label className="grow">
													Zichtbaarheid
												</Label>
												<SliderOutput />
											</div>
											<div
												className="rounded-md"
												style={alphaPatternStyle}
											>
												<SliderTrack className="shadow-border-bottom-sm h-8 w-full rounded-md after:rounded-md">
													<div className="translate-y-4">
														<Thumb />
													</div>
												</SliderTrack>
											</div>
										</ColorSlider>
									)}

									{lastSwatches.length > 0 && (
										<ColorSwatchPicker className="grid grid-cols-6 gap-1 pt-2">
											{lastSwatches
												.filter((swatch) => {
													// If it contains opacity, only show it if the color picker supports it
													if (swatch.length > 7)
														return isOpacityAllowed;
													return true;
												})
												.map((swatch) => (
													<ColorSwatchPickerItem
														key={swatch}
														color={swatch}
														className="rounded-md"
														style={
															alphaPatternStyle
														}
													>
														<ColorSwatch className="sw-full shadow-border-bottom-sm aspect-square cursor-pointer rounded-md shadow-md after:rounded-md" />
													</ColorSwatchPickerItem>
												))}
										</ColorSwatchPicker>
									)}

									{typeof window !== 'undefined' &&
										typeof window?.EyeDropper !==
											'undefined' && (
											<div className="pt-2">
												<IconButton
													icon={mdiEyedropper}
													title="Kies een kleur van je scherm"
													label="Kleur van scherm"
													onClick={() => {
														if (!window.EyeDropper)
															return;
														new window.EyeDropper()
															.open()
															.then((color) => {
																setColor(
																	color.sRGBHex,
																);
															});
													}}
												/>
											</div>
										)}
								</div>
							</div>
						</Dialog>
					</Popover>
				</DialogTrigger>
			</ColorPicker>
			<Input
				className="w-32 min-w-0 rounded-md bg-slate-100 px-3 py-1 text-center text-lg font-bold text-slate-800 shadow-inner"
				aria-label={label}
				value={inputValue}
				onChange={(event) => {
					setInputValue(event.target.value);
					if (
						isColorValid(event.target.value) &&
						event.target.value.length >= 7
					)
						setColor(event.target.value);
				}}
				onBlur={(event) => {
					// If valid color, set it
					if (isColorValid(event.target.value))
						setColor(event.target.value);
				}}
				onKeyDown={(event) => {
					// If enter, set color
					if (
						event.key === 'Enter' &&
						'value' in event.target &&
						typeof event.target.value === 'string' &&
						isColorValid(event.target.value)
					)
						setColor(event.target.value);
				}}
				maxLength={isOpacityAllowed ? 9 : 7}
			/>
		</div>
	);
};

export default SingleColorPicker;
